<template>
  <div class="demo">
    <div class="demo_right">
      <div class="righttop">
        <el-button type="primary" @click="Speaktext">{{ answer }}</el-button>
      </div>
    </div>
    <div class="textbox">
      <span>{{ text }}</span>
      <span style="opacity: 0.6">{{ text1 }}</span>
    </div>
  </div>
</template>

<script>
import VoiceToText from "./VoiceToText";
export default {
  mixins: [VoiceToText],
  data() {
    return {
      answer: "开始说话",
      sessionId: "",
      text: "",
      text1: "",
      loading: false,
    };
  },
  watch: {
    resultText1(val) {
      this.text1 = val;
    },
    resultText(val) {
      if (val) {
        this.text += val;
        this.text1 = "";
        // this.huida;
      }
    },
  },
  mounted() {},
  methods: {
    Speaktext() {
      if (this.answer === "开始说话") {
        this.start();
        this.answer = "停止说话";
      } else {
        this.answer = "开始说话";
        this.end();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.demo {
  width: 100vw;
  height: 100vh;
  //   display: flex;
  .textbox {
    // width: 500px;
    height: 800px;
    overflow: auto;
  }
  .demo_right {
    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .ipt {
        height: 100%;
      }
      .el-button {
        height: 100%;
      }
    }
  }
}
</style>